<template>
  <v-card
    class="mx-auto pa-5 primary cursor" 
    elevation="10"
    width="80%"
    height="100px"
    @click="goTo()"
  >
    <v-icon size="40px" color="white"> mdi-{{icon}}</v-icon>
    <p class="white--text text-body-2 text-sm-body-2 text-md-subtitle-2 text-center">{{title}}</p>
    <strong class="white--text text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">
      {{info}}
    </strong>
  </v-card>
</template>

<script>
export default {
  props: {
    icon: String,
    title: String,
    info: String,
    link: String
  },
  methods: {
    goTo() {
      window.open(this.link);
    },
  },
}
</script>

<style>

</style>
