<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        class="mx-auto my-2 white"
        rounded="8"
        elevation="10"
        max-width="344"
        :height="cardSize"
      >
        <v-img aspect-ratio="1" :src="service.image" :max-height="cardSize - 50"></v-img>

        <v-card-text class="black--text">
          <h2 class="text-h6 primary--text" @click="goToW()">
            {{ service.name }}
          </h2>
          <!-- {{ service.shortDescription }} -->
        </v-card-text>

        <v-fade-transition>
          <v-overlay v-if="hover" absolute color="primary">
            <v-btn class="primary ma-5" dark @click="goToW()">
              <v-icon>mdi-whatsapp</v-icon>
              ¿Más Info?
            </v-btn>
          </v-overlay>
        </v-fade-transition>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  props: {
    service: {},
  },
  data: () => ({
    overlay: false,
  }),
  methods: {
    goTo(route) {
      this.$router.push({ name: route });
    },
    goToW() {
      window.open(
        `https://wa.me/5216144956603?text=${this.service.message.replace(/\s/g, '%20')}`
      );
    },
  },
  computed:{
    cardSize(){
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = 300
          break;
        case "sm":
          value = 350
          break;
        case "md":
          value = 300
          break;
        case "lg":
        case "xl":
          value = 380
          break;
      }
      return value;
    }
  }
};
</script>

<style></style>
