import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes:{
      light:{
        primary: "#6500E1",
        secondary: "#ff546c",
        grayzelin: "#DFE1E0",
        zpink: "#FF7BAC"
      }
    }
  }
});
