<template>
  <v-container fluid>
    <div class="pt-16 ma-5">
      <span class="text-h3"
        >Nuestros <strong class="primary--text">clientes</strong>
      </span>
      <br />
      <span class="text-h5">
        Trabajamos con una gran variedad de marcas y empresas con enfoques distintos y a cada una la tendimos según sus necesidades y objetivos.
      </span>
    </div>
    <v-row justify="center">
      <v-col
        :cols="detail ? 12 : 12"
        :sm="detail ? 3 : 12"
        :md="detail ? 3 : 12"
      >
        <v-row justify="center">
          <v-col
            v-for="(client, index) in clients"
            :key="index"
            class="white justify-center"
            :cols="3"
            :sm="detail ? 4 : 3"
          >
            <v-img
              :src="client.image"
              aspect-ratio="1"
              class="white elevation-3"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical class="primary"></v-divider>
      <v-col
        :cols="detail ? 12 : 0"
        :sm="detail ? 9 : 0"
        :md="detail ? 8 : 0"
        v-if="detail"
      >
        <div class="mb-16 text-center">
          <span class="text-h3 primary--text">Información adicional</span>
          <span class="text-h3 red--text mx-16 pointer" @click="detail = false"
            >[X]</span
          >
        </div>
        <div>
          <h3>{{ selectedClient.name }}</h3>
          <h3>Ubicación: {{ selectedClient.city }}</h3>
          Servicios:

          <div v-for="(service, index) in selectedClient.services" :key="index">
            <h3>{{ service.name }}</h3>
            <h3>{{ service.descripcion }}</h3>
            <v-row>
              <v-col
                v-for="(portafolio, index) in service.portafolio"
                :key="index"
                :cols="service.name === 'ECommerce' ? 12 : 12"
              >
                <v-img
                  v-if="service.name !== 'ECommerce'"
                  max-height="600"
                  :src="portafolio"
                />
                <v-img v-else aspect-ratio="1" height="600" :src="portafolio">
                </v-img>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
    <div class="pt-16 ma-5">
      <span class="text-h3"
        >Cobertura <strong class="primary--text"> internacional</strong>
      </span>
      <br />
      <span class="text-h5">
        En Zelin no nos detenemos por las fronteras geográficas porque la imaginación no tiene límites, por eso, llegamos a donde quiera que tú estés con nuestras herramientas y estrategias digitales.
      </span>
    </div>
    <v-row>
      <v-col cols="6">
        <!-- //https://simplemaps.com/custom/us#finish -->
        <v-img src="/images/country.svg"></v-img>
      </v-col>
      <v-col cols="6">
        <v-img src="/images/us.svg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      detail: false,
      selected: 0,
      clients: [
        {
          name: "Cavall7",
          city: "Delicias, chihuahua",
          image: "/images/clientes/grid2-2-11.png",
          services: [
            {
              name: "Administración de redes sociales",
              description: "Manejo de redes sociales, de contenido",
              portafolio: ["/images/cavallfeed.png"],
            },
            {
              name: "Ecommerce",
              description: "Manejo de redes sociales, de contenido",
              portafolio: [
                "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
              ],
            },
          ],
        },
        {
          name: "Quota arquitectos",
          city: "Chihuahua, chih.",
          image: "/images/clientes/grid2-8-11.png",
          services: [],
        },
        // {
        //   name: "Hotel Sicomoro",
        //   city: "Chihuahua, chihuahua",
        //   image: "/images/logoSicomoro.jpeg",
        //   services: [
        //     {
        //       name: "Administración de redes sociales",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/sicomorofeed.png"],
        //     },
        //     {
        //       name: "Website",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: [
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273127341_6920131784726334_4793015729271369728_n.jpg?_nc_cat=102&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeFp4kYJqIigavle3hAJh-d6YfT4lEYjr4dh9PiURiOvh0Wg272Yxda3SPZCPrLriIs&_nc_ohc=-0Xfa-csJ0IAX9iCmur&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT8hu-kiPgJzosKf1Zqmq6zHtM9weCQRKJqAWjIPwLfnfg&oe=61FD5739",
        //       ],
        //     },
        //   ],
        // },
        // {
        //   name: "Mexicanisima",
        //   city: "Denver,Colorado",
        //   image: "/images/mexi.jpeg",
        //   services: [
        //     {
        //       name: "Administración de redes sociales",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/mexifeed.png"],
        //     },
        //     {
        //       name: "Website",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: [
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/272966792_1288153681688404_915183959621594831_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFpdipDRsYfMsIBtiijfGhWnI9MnBot-d-cj0ycGi353xKKMOfsLxS96LrFDXNQTMs&_nc_ohc=YQjAA-Dk1-0AX_PVCyf&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT_p95bxK7YTV2QjiC1ZIAUtStWUD4VhX-XTa56RtSKn7A&oe=61FD4518",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273008748_1287510371752735_4093589161390391726_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeHzCyGEt-fZojO8f6CLIhpsW58QnF0APXJbnxCcXQA9coyqSWZ9ULzUBriLzvDrAvE&_nc_ohc=5_ZB1or3Q-4AX9gzqHt&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT9OnGNYGp8FrdC6H4b6dLN-u6lVx7zkteFiCdMvC2gzZg&oe=61FE0B7C",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/272966792_1288153681688404_915183959621594831_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFpdipDRsYfMsIBtiijfGhWnI9MnBot-d-cj0ycGi353xKKMOfsLxS96LrFDXNQTMs&_nc_ohc=YQjAA-Dk1-0AX_PVCyf&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT_p95bxK7YTV2QjiC1ZIAUtStWUD4VhX-XTa56RtSKn7A&oe=61FD4518",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/273008748_1287510371752735_4093589161390391726_n.jpg?_nc_cat=105&ccb=1-5&_nc_sid=730e14&_nc_eui2=AeHzCyGEt-fZojO8f6CLIhpsW58QnF0APXJbnxCcXQA9coyqSWZ9ULzUBriLzvDrAvE&_nc_ohc=5_ZB1or3Q-4AX9gzqHt&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT9OnGNYGp8FrdC6H4b6dLN-u6lVx7zkteFiCdMvC2gzZg&oe=61FE0B7C",
        //         "https://scontent.fcuu1-1.fna.fbcdn.net/v/t39.30808-6/272966792_1288153681688404_915183959621594831_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=8bfeb9&_nc_eui2=AeFpdipDRsYfMsIBtiijfGhWnI9MnBot-d-cj0ycGi353xKKMOfsLxS96LrFDXNQTMs&_nc_ohc=YQjAA-Dk1-0AX_PVCyf&_nc_ht=scontent.fcuu1-1.fna&oh=00_AT_p95bxK7YTV2QjiC1ZIAUtStWUD4VhX-XTa56RtSKn7A&oe=61FD4518",
        //       ],
        //     },
        //   ],
        // },
        // {
        //   name: "Hannas Cosmetics",
        //   city: "Monterrey,NuevoLeon",
        //   image:
        //     "https://hannascosmetics.com/img/hannascosmetics-logo-1617214617.jpg",
        //   services: [
        //     {
        //       name: "ECommerce",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/hannasfull.png"],
        //     },
        //   ],
        // },
        // {
        //   name: "All Natural",
        //   city: "Monterrey,NuevoLeon",
        //   image: "https://allnatural.com.mx/img/allnatural-logo-1643564853.jpg",
        //   services: [
        //     {
        //       name: "ECommerce",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/allfull.png"],
        //     },
        //   ],
        // },
        // {
        //   name: "MariaMia CUU",
        //   city: "Chihuahua,Chihuahua",
        //   image: "/images/mariamia.png",
        //   services: [
        //     {
        //       name: "ECommerce",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/mariamiafull.png"],
        //     },
        //   ],
        // },
        // {
        //   name: "Essences Spa",
        //   city: "Chihuahua,Chihuahua",
        //   image: "/images/esse.jpeg",
        //   services: [
        //     {
        //       name: "Manejo de redes sociales, de contenido",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/essefeed.png"],
        //     },
        //   ],
        // },
        // {
        //   name: "Molina Climas y Servicios",
        //   city: "Chihuahua,Chihuahua",
        //   image: "/images/molinalogo.jpeg",
        //   services: [
        //     {
        //       name: "Manejo de redes sociales, de contenido",
        //       description: "Manejo de redes sociales, de contenido",
        //       portafolio: ["/images/molinafeed.png"],
        //     },
        //   ],
        // },
      ],
    };
  },
  methods: {
    showDetail(n) {
      this.detail = true;
      this.selected = n;
    },
  },
  computed: {
    selectedClient() {
      return this.clients[this.selected];
    },
  },
};
</script>

<style></style>
