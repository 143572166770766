<template>
  <v-app>
    <nav-bar />
    <v-main>
      <router-view />
    </v-main>
    <front-footer />
  </v-app>
</template>

<script>
import NavBar from "@/components/Front/NavBar";
import FrontFooter from '@/components/Front/FrontFooter.vue';

export default {
  name: "App",
  components:{
    NavBar,
    FrontFooter
  },
  data: () => ({
    //
  }),
};
</script>
