<template>
  <div>
    <v-sheet class="text-center">
      <div class="pt-16">
        <p class="text-h6 text-sm-h4 text-md-h5">Con estos servicios podemos iniciar:</p>
        <v-row class="pa-1" no-gutters justify="center">
          <v-col
            class="pa-1"
            v-for="service in services"
            :key="service.name"
            cols="6"
            md="3"
            lg="3"
          >
            <service-card :service="service"></service-card>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import ServiceCard from "@/components/Front/ServiceCard.vue";
export default {
  components: { ServiceCard },
  data() {
    return {
      services: [
        {
          name: "Ecommerce",
          image:
            "https://images.unsplash.com/photo-1582004531564-50f300aae039?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
          shortDescription: "Tu nueva tienda en linea",
          message: 'Quiero empezar a vender en línea.',
          link: "/ecommerce",
        },
        {
          name: "Canales digitales",
          image:
            "https://images.unsplash.com/photo-1517292987719-0369a794ec0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
          shortDescription: "Quieres unas redes que causen impacto?",
          message: 'Me interesa tener mis propias redes sociales. ',
          link: "/social",
        },
        {
          name: "Sitios Web",
          image:
            "https://images.unsplash.com/photo-1481487196290-c152efe083f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1262&q=80",
          shortDescription: "Presencia digital de alto impacto",
          message: 'Quiero una página web.',
          link: "/sitiosweb",
        },
        {
          name: "Branding",
          image:
            "https://images.unsplash.com/photo-1498075702571-ecb018f3752d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1178&q=80",
          shortDescription: "Una marca que haga eco?",
          message: 'Me gustaría renovar mi marca.',
          link: "/branding",
        },
      ],
      services2: [
        {
          name: "Ecommerce",
          image:
            "https://images.unsplash.com/photo-1582004531564-50f300aae039?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80",
          shortDescription: "Tu nueva tienda en linea",
          link: "/ecommerce",
        },
        {
          name: "Admon. de canales digitales",
          image:
            "https://images.unsplash.com/photo-1517292987719-0369a794ec0f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80",
          shortDescription: "Quieres unas redes que causen impacto?",
          link: "/ecommerce",
        },
        {
          name: "Data-Marketing",
          image:
            "https://images.unsplash.com/photo-1551288049-bebda4e38f71?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
          shortDescription: "Ingenieria aplicada a tus estrategias digitales",
          link: "/ecommerce",
        },
        {
          name: "Contenido Original",
          image:
            "https://images.unsplash.com/photo-1605076896228-086cda8868c9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80",
          shortDescription: "Generamos tu contenido fotografia y video.",
          link: "/ecommerce",
        },
        {
          name: "Sitios Web",
          image:
            "https://images.unsplash.com/photo-1481487196290-c152efe083f5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1262&q=80",
          shortDescription: "Presencia digital de alto impacto",
          link: "/ecommerce",
        },
        {
          name: "Branding",
          image:
            "https://images.unsplash.com/photo-1498075702571-ecb018f3752d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1178&q=80",
          shortDescription: "Una marca que haga eco?",
          link: "/ecommerce",
        },
        {
          name: "Ads Managments",
          image:
            "https://images.unsplash.com/photo-1504868584819-f8e8b4b6d7e3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1176&q=80",
          shortDescription: "Ingenieria aplicada a tus pautas digitales",
          link: "/ecommerce",
        },
        {
          name: "Brand Experience",
          image:
            "https://images.unsplash.com/photo-1516321497487-e288fb19713f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80",
          shortDescription: "Consultoría para posicionamiento digital",
          link: "/ecommerce",
        },
      ],
    };
  },
};
</script>

<style></style>
