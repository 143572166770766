<template>
  <v-sheet class="text-center">
    <div class="pt-16 px-10">
      <p class="text-h6 text-sm-h4 text-md-h2">
        ¿Quieres que tu marca destaque por encima de tu competencia?
        <strong>
          Sólo llámanos.
        </strong>
      </p>
      <p class="text-subtitle-1 text-sm-h6 text-md-h5">
        Tu departamento de marketing al precio de un empleado. Estamos a un mensaje de distancia.
      </p>
    </div>
    <v-row justify="center" align="center" class="mt-10">
      <v-col cols="12" md="6">
        <v-row justify="center">
          <v-col cols="6" sm="4">
            <contact-card
              icon="phone"
              info="(+52) 614 414 3332 ext.1007"
              link="tel:+526144143332,1007"
            ></contact-card>
          </v-col>
          <v-col cols="6" sm="4">
            <contact-card
              icon="whatsapp"
              info="(+52) 614 495 6603"
              :link="whatsappMessage"
            ></contact-card>
          </v-col>
          <v-col cols="6" sm="4">
            <contact-card
              icon="email"
              info="hola@zelin.studio"
              link="mailto:hola@zelin.studio"
            ></contact-card>
          </v-col>
          <v-col cols="6" sm="4">
            <contact-card
              icon="facebook"
              info="/zelin.studio"
              link="https://www.facebook.com/zelin.studio"
            ></contact-card>
          </v-col>
          <v-col cols="6" sm="4">
            <contact-card
              icon="instagram"
              info="@zelin.studio"
              link="https://www.instagram.com/zelin.studio"
            ></contact-card>
          </v-col>
          <v-col cols="6" sm="4">
            <contact-card
              title="TikTok"
              info="@zelin.studio"
              link="https://www.tiktok.com/@zelin_studio"
            ></contact-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="6">
        <v-row>
          <v-col cols="12">
            <v-card
              class="mx-auto pa-10 primary"
              elevation="10"
              width="90%"
              height="280px"
            >
              <v-icon size="80px" color="white"> mdi-map</v-icon>
              <p class="white--text text-body-2 text-sm-body-2 text-md-subtitle-2 text-center">
              Ave Francisco Villa 6501 
              </p>
              <p class="white--text text-body-2 text-sm-body-2 text-md-subtitle-2 text-center">
              Panamericana, 31210 
              </p>
              <p class="white--text text-body-2 text-sm-body-2 text-md-subtitle-2 text-center">
              Chihuahua, Chih.
              </p>
              <strong class="white--text text-body-2 text-sm-body-1 text-md-subtitle-2 text-center font-weight-bold">
                {{info}}
              </strong>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import ContactCard from "@/components/Front/ContactCard.vue";

export default {
  components:{ ContactCard},
  data(){
    return{
      message: "Zelineño, ayúdame a construir mi marca."
    }
  },
  computed:{
    whatsappMessage(){
      return `https://wa.me/5216144956603?text=${this.message.replace(/\s/g, "%20")}`
    }
  },
  methods:{
    goToUrl(url){
      window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  },
  goToW(){
      window.open("https://wa.me/5216144956603");
    }
  }
};
</script>

<style></style>
