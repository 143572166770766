<template>
  <div class="text-center ma-10 justify-center">
    <div class="py-16">
      <p class="text-h6 text-sm-h4 text-md-h2">
        Es cuestión de confianza mutua,
        <strong>
          así como ellos.
        </strong>
      </p>
    </div>
    <v-row>
      <v-col cols="3" sm="3" md="3" lg="1" v-for="brand in brands" :key="brand.name" class="justify-center mx-auto text-center">
          <v-img 
            class="justify-center mx-auto"
            max-height="100"
            width="200"
            contain
            :aspect-ratio="backSize"
            :src="'/images/brands/brand-'+brand.name+'.png'"
          />        
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data(){
    return{      
      brands:[
        {
          name: "sicomoro",
        },
        {
          name: "cavall",
        },
        {
          name: "ariel",
        },
        {
          name: "ehorsesale",
        },
        {
          name: "axolotl",
        },
        {
          name: "leonea",
        },
        {
          name: "quota",
        },
        {
          name: "mubali",
        },
      ]
    }
  },
  computed: {
    backSize() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = '1.16';
          break;
        case "sm":
          value = '1.16';
          break;
        case "md":
          value = '1';
          break;
        case "lg":
          value = '1';
          break;
        case "xl":
          value = '1';
          break;
      }
      return value;
    },
  },

};
</script>

<style></style>
