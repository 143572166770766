<template>
  <div>
    <v-sheet color="primary" class="pt-2" :height="backSize">
      <v-row>
        <v-col class="justify-center align-center text-center pt-15 my-15">
          <h1
            class="white--text text-center py-xl-15 mt-xl-15 py-lg-16 mt-lg-16 ma-6 text-h5 text-sm-h4 text-md-h5 text-lg-h4"
          >
            Studio creativo ideal para
            <div class="mt-5">
              <span class="zpink--text mx-auto">
                <vue-typed-js
                  :strings="categories"
                  :loop="true"
                  :showCursor="false"
                >
                  <h1 class="typing mx-auto"></h1>
                </vue-typed-js>
              </span>
            </div>            
          </h1>
        </v-col>
        <v-col cols="6" class="hidden-sm-and-down">
          <v-img
            contain
            src="/images/home/Zta.png"
            :max-height="backSize + 200"
            aspect-ratio="1"
          ></v-img>
        </v-col>
      </v-row>
    </v-sheet>
    <svg
      class="pt-n5"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill="#6500E1"
        fill-opacity="1"
        d="M0,128L80,154.7C160,181,320,235,480,218.7C640,203,800,117,960,90.7C1120,64,1280,96,1360,112L1440,128L1440,0L1360,0C1280,0,1120,0,960,0C800,0,640,0,480,0C320,0,160,0,80,0L0,0Z"
      ></path>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categories: [
        "Emprendedores.",
        "Hoteles.",
        "Cafeterías.",
        "Restaurantes.",
        "Bares.",
        "Barberías.",
        "Ferreterías.",
        "Todos.",
      ],
    };
  },
  methods: {
    goToW() {
      window.open(
        `https://wa.me/5216144956603?text=Me%20interesan%20los%20servicios%20de%20Zelin`
      );
    },
  },
  computed: {
    sizeScreen() {
      return this.$vuetify.breakpoint.name;
    },
    backSize() {
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          value = 350;
          break;
        case "sm":
          value = 400;
          break;
        case "md":
          value = 500;
          break;
        case "lg":
          value = 600;
          break;
        case "xl":
          value = 700;
          break;
      }
      return value;
    },
  },
};
</script>

<style>
</style>
