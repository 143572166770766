<template>
  <v-app-bar app color="white" elevation="0">
    <div class="d-flex align-center justify-start">
      <v-img
        @click="goTo('Home');"
        alt="Zelin Studio Logo"
        contain
        :src="imageURL"
        height="800"
      />
    </div>
    <v-spacer></v-spacer>
    <v-btn text class="primary--text hidden-sm-and-down" @click="goTo('Home');">Inicio</v-btn>
    <!-- <v-btn text class="primary--text hidden-sm-and-down" @click="goTo('About-us');">
      <v-img alt="zelineño" contain src="/images/home/zelineñoBW.png" width="30"/>
      Zelineños
    </v-btn>
    <v-btn text class="primary--text hidden-sm-and-down" @click="goTo('Portafolio');">Portafolio</v-btn>
    <v-btn text class="primary--text hidden-sm-and-down" @click="goTo('About-us');">Contacto</v-btn>
    <v-btn class="primary hidden-sm-and-down" @click="goTo('Servicios');">Servicios</v-btn>
    <span class="mx-4 hidden-sm-and-down"> | </span> -->
    <v-btn text class="primary--text" @click="goTo('Ebooks');"><v-icon>mdi-book-open</v-icon></v-btn>
    <v-btn icon class="primary--text" @click="goToUrl('https://www.facebook.com/zelin.studio')"><v-icon>mdi-facebook</v-icon></v-btn>
    <v-btn icon class="primary--text" @click="goToUrl('https://www.instagram.com/zelin.studio/')"><v-icon>mdi-instagram</v-icon></v-btn>
  </v-app-bar>
</template>

<script>
export default {
  computed:{
    imageURL(){
      let value;
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
        case "sm":
          value = "/images/logos/zelin-logo-z.png"
          break;
        case "md":
        case "lg":
        case "xl":
          value = "/images/logos/zelin-logo.png"
          break;
      }
      return value;
    }
  },
  methods:{
    goTo(route){
      this.$router.push({ name: route });
    },
    goToUrl(url){
      window.open(url, '_blank');
    }
  }
}
</script>

<style></style>
