<template>
  <div class="pa-10 mt-10 text-center">
    <img src="/images/home/headsBW.png" height="150px" alt="">
    <p class="text-h6 text-sm-h4 text-md-h2">
      <strong>
        No somos 
      </strong>
      una agencia más.
    </p>
    <p class="text-subtitle-1 text-sm-h6 text-md-h5">
      Somos un equipo multidisciplinario de mercadotecnia digital comprometidos en construir lo mejor de lo mejor para tu marca o empresa creando estrategias con alto impacto creativo. 
    </p>
  </div> 
</template>

<script>
export default {};
</script>

<style></style>
