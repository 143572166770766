<template>
  <div>
    <front-header class="mb-n16 pb-n16" />
    <front-what />
    <front-more />
    <front-brands />
    <front-contact />
    <v-btn
      class="float"
      color="#25D366"
      dark
      bottom
      right
      fab
      fixed
      @click="goToWhats()"
    >
      <v-icon>mdi-whatsapp</v-icon>
    </v-btn>
  </div>
</template>

<script>
import FrontHeader from "../components/Front/FrontHeader.vue";
import FrontWhat from "../components/Front/FrontWhat.vue";
import FrontMore from "../components/Front/FrontMore.vue";
import FrontBrands from "../components/Front/FrontBrands.vue";
import FrontContact from "../components/Front/FrontContact.vue";

export default {
  name: "Home",
  components: { FrontHeader, FrontWhat, FrontMore, FrontBrands, FrontContact },
  data() {
    return {
      message: "Zelineño, ayúdame a construir mi marca.",
    };
  },
  methods: {
    goToWhats() {
      window.open(
        `https://wa.me/5216144956603?text=${this.message.replace(/\s/g, "%20")}`
      );
    },
  },
};
</script>