<template>
  <v-container fluid>
    <div class="pt-16 ma-5">
      <span class="text-h3"
        >¿Qué es<strong class="primary--text"> Zelin</strong> Studio?</span
      >
      <br />
      <span class="text-h5">
        Somos un grupo multidisciplinario trabajando juntos para ayudar a marcas como la tuya a ser parte de la tendencia digital.
      </span>
    </div>
    <v-row justify="center">
      <v-col
        v-for="ebook in ebooks"
        :key="ebook.name"
        class="d-flex child-flex"
        cols="12"
        sm="6"
        md="3"
      >
        <v-hover>
          <template v-slot:default="{ hover }">
            <v-card class="mx-auto my-2 white" elevation="10"  max-height="300">
              <v-img :src="ebook.image" max-height="200" ></v-img>

              <v-card-text class="black--text">
                <h2 class="text-h6 primary--text"  @click="goToUrl(ebook.link)">
                  {{ ebook.name }}
                </h2>
                {{ebook.shortDescription}}
              </v-card-text>

              <v-fade-transition>
                <v-overlay v-if="hover" absolute color="black">
                  <v-btn class="secondary" @click="goToUrl(ebook.link)">
                    <v-icon>mdi-download-box-outline</v-icon> Descargar
                  </v-btn>
                </v-overlay>
              </v-fade-transition>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      ebooks: [
        {
          name: "2022",
          shortDescription:"Enfrenta el 2022",
          image: "/images/brandgarro.jpeg",
          link: `https://wa.me/5216144956603?text=Me%20interesa%20el%20ebook%20Enfrenta%20el%202022%20"`,
          //link: "https://drive.google.com/file/d/1K7tAmz4NlWWofKCGUtZr1w7eEXqRbCfh/view?usp=sharing",
        },
      ],
    };
  },
  methods:{
     goToUrl(url){
      window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
    }
  }
};
</script>

<style></style>
