<template>
  <v-sheet>   
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#6500E1" fill-opacity="1" d="M0,256L60,229.3C120,203,240,149,360,160C480,171,600,245,720,250.7C840,256,960,192,1080,181.3C1200,171,1320,213,1380,234.7L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
    <div class="primary mt-n3 pb-10 text-center">
      <h1 class="white--text text-center">
        Siguenos
      </h1>
      <v-btn
        icon
        class="white--text"
        @click="goToUrl('https://www.facebook.com/zelin.studio')"
        ><v-icon>mdi-facebook</v-icon></v-btn
      >
      <v-btn
        icon
        class="white--text"
        @click="goToUrl('https://www.instagram.com/zelin.studio/')"
        ><v-icon>mdi-instagram</v-icon></v-btn
      >

    </div>
  </v-sheet>
</template>

<script>
export default {
methods:{
    goToUrl(url){
      window.open(
      url,
      '_blank' // <- This is what makes it open in a new window.
    );
  }
  }
}
</script>

<style>

</style>